<div class="background-image-container mt-ncs" style="position: relative; width: 100%; height: 100vh; overflow: hidden;">
  <div class="container" style="position: relative; z-index: 1; color: white;">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
        <div>
        </div>
      </div>
    </div>
    <small class="credits"></small>
  </div>
  <picture class="background-image" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 0;">
    <source type="image/jpg" srcset="assets/dspace/images/HOME.jpg">
    <img alt="Description de l'image" src="assets/dspace/images/HOME.jpg" style="width: 100%; height: 100%;" />
  </picture>
</div>
